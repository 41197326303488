<template>

  <div class="modatta-invite">
    <div class="modatta-container container">
      <div class="modatta-header">
        <div class="modatta-row">
          <div class="modatta-col">
            <router-link to="/" class="logo">
             <img src="/img/invite/logo.png" alt="Modatta Logo" />
            </router-link>
          </div>
          <div class="modatta-col">
            <modatta-language-dropdown></modatta-language-dropdown>
          </div>
        </div>
      </div>
      <div class="modatta-frame">
        <div class="modatta-inner">
        <div class="modatta-f-wrap f-45 limit-to-heading">
          <h1 class="modatta-f"><span v-html="$t('invite.title')"></span></h1>
        </div>
        <div class="modatta-f-wrap f-55 limit-to-heading">
          <h2 class="modatta-f">{{ $t('invite.subtitle') }}</h2>
        </div>
        <div class="modatta-f-wrap f-75 limit-to-heading-sm">
          <h3 class="modatta-f">{{ $t('invite.text_1') }}</h3>
        </div>
        <div class="modatta-inner-container">
          <div class="modatta-phone f-75">
            <vue-tel-input v-model="phoneNumber" autoFormat :defaultCountry="'br'" :onlyCountries="['br']"
              :inputOptions="inputOptions" @input="onInput"></vue-tel-input>
            <button class="modatta-button primary" @click="acceptInvite()"
              :disabled="!inviteObject.valid || !phoneObject.valid"><span v-html="$t('invite.button')"></span></button>
            <b-loading v-model="joinBtnLoading"></b-loading>
          </div>
          <div class="f-75">
          <p>{{ $t('invite.text_2') }}</p>
          </div>
          <div class="modatta-download f-75">
            <button class="modatta-button secondary" :class="{ 'is-disabled': !canDownload }"
              @click="handleDownloadClick" :disabled="!canDownload">
              {{ $t(`invite.download_app_button`) }}
            </button>
          </div>
        </div>
      </div>
      </div>
      <div class="modatta-footer">
        <div class="modatta-row">
            <ul>
              <li><a href="https://www.modatta.com/legal" target="_blank">{{ $t('footer.tc') }}</a></li>
              <li><a href="https://www.modatta.com/legal" target="_blank">{{ $t('footer.privacy_policy') }}</a></li>
              <li><a href="https://www.modatta.com/legal" target="_blank">{{ $t('footer.cookies') }}</a></li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import axios from "axios";
export default {
  components: { VueTelInput },
  data() {
    return {
      canDownload: false,
      inviteObject: {
        ref: this.$route.query.ref,
        valid: false,
      },
      phoneNumber: null,
      phoneObject: {},
      inputOptions: {
        placeholder: "955 978 7649",
        required: true,
      },
      joinBtnLoading: false,
    };
  },
  mounted() {
    this.validateRefParam();
  },
  computed: {},
  methods: {
    async acceptInvite() {
      try {
        this.joinBtnLoading = true;
        await axios.post(
          process.env.VUE_APP_API_URL + "external/invitations",
          {
            invite_ref: this.inviteObject.ref,
            phone_prefix: this.phoneObject.countryCallingCode,
            phone_number: this.phoneObject.nationalNumber,
          },
          { "Content-Type": "application/json", Accept: "application/json" }
        );
        this.$buefy.dialog.alert({
          title: this.$t("invite.success.title"),
          message: this.$t("invite.success.body"),
          confirmText: this.$t("invite.ok"),
          onConfirm: () => this.handleDownloadClick(),
        });
        this.canDownload = true;
      } catch (error) {
        this.errorHandle(error);
      } finally {
        this.joinBtnLoading = false;
      }
    },

    errorHandle(error) {
      if ([422, 406, 404].includes(error.response.status)) {
        this.$buefy.dialog.alert({
          title: this.$t("invite.alert.upswrong_title"),
          message: this.$t("invite.alert.upswrong_body"),
          confirmText: this.$t("invite.ok"),
          onConfirm: () => this.handleDownloadClick(),
        });
        this.canDownload = false;
      }
    },

    validateRefParam() {
      if (!this.inviteObject.ref || this.inviteObject.ref.trim() == "") {
        this.$buefy.dialog.alert({
          title: this.$t("invite.alert.upswrong_title"),
          message: this.$t("invite.alert.upswrong_body"),
          confirmText: this.$t("invite.ok"),
          onConfirm: () => this.handleDownloadClick(),
        });
        this.canDownload = false;
      } else {
        this.inviteObject.valid = true;
      }
    },

    onInput(number, phoneObject) {
      this.phoneObject = phoneObject;
    },

    handleDownloadClick() {
      // const item = this.getStoreLink();
      const item = "https://onelink.to/modatta";
      window.open(item, "_blank");
    },
    getStoreLink() {
      const appleLink = process.env.VUE_APP_APPLE_STORE;
      const googleLink = process.env.VUE_APP_GOOGLE_STORE;
      const isIOS =
        (/Mac|iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream;
      return isIOS ? appleLink : googleLink;
    },
  },
};
</script>

<style lang="scss">
</style>