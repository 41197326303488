import Vue from "vue";
import vueVimeoPlayer from "vue-vimeo-player";
import i18n from "./i18n";
import Buefy from "buefy";
import "./styles/app.scss";
import App from "./App.vue";
import router from "./router";

Vue.use(Buefy);
Vue.use(vueVimeoPlayer);

// VUE INSTANCE
Vue.config.productionTip = false;
const files = require.context("./", true, /\.vue$/i);
files.keys().map((key) =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
