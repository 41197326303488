import Vue from "vue";
import VueRouter from "vue-router";
import Invite from "../views/Invite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Invite",
    component: Invite,
    meta: { title: "Data Influencers • Modatta" },
  },
  {
    path: "/pt",
    name: "Invite-PT",
    component: Invite,
    meta: { title: "Data Influencers • Modatta" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
});

export default router;
